import $ from 'jquery'

$(function() {
  $(document).ready(function() {
    var $iconLink = $("link[rel~='icon']");
    var faviconUrl = $iconLink.attr('href') || window.location.origin + "/favicon_color.ico";
    var img = document.createElement('img');
    img.crossOrigin = 'anonymous';

    var onFaviconLoaded = function() {
      var canvas = document.createElement("canvas");
      canvas.width = 16;
      canvas.height = 16;

      var context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);
      context.globalCompositeOperation = "source-in";

      var env = $iconLink.data('environment');
      var colorHash = {
        "edge" : "#d9534f",
        "staging" : "#5cb85c",
        "development" : "#f0ad4e"
      };

      context.fillStyle = colorHash[env] || "#000";
      context.fillRect(0, 0, 16, 16);
      context.fill();
      $iconLink.attr('type', 'image/x-icon');
      $iconLink.attr('href', canvas.toDataURL());
    };

    img.addEventListener("load", onFaviconLoaded);
    img.src = faviconUrl;
  })
})
